<template>
  <!-- <div> -->
  <modal :name="modal_name" class="final-modal" transition="nice-modal-fade">
    <div class="v-modal-content">
      <div class="v-modal-header">
        <span class="v-modal-dialog-title v-modal-title">MOVE ITEMS</span>
      </div>
      <div class="v-modal-body pl-0 pr-0">
        <div class="v-modal-layout">
          <div class="m-4 p-2 move-customer text-white font-weight-bold">
            <div class="d-flex flex-nowrap">
              <div class="div-content">
                <div class="content-left specmeter">Customer Name:</div>
                <div class="content-right w-150">
                  <span class="right-div whiteSpace">{{orders.customer_name}}</span>
                </div>
              </div>
              <div class="div-content">
                <div class="content-left specmeter left-flex">Order Id:</div>
                <div class="content-right">
                  <span class="right-div">{{orders.order_id}}</span>
                </div>
              </div>
            </div>
            <div class="d-flex flex-nowrap">
              <div class="div-content">
                <div class="content-left specmeter">Current Table No:</div>
                <div class="content-right">
                  <span class="right-div" v-if="orders.tables.length>0">{{orders.tables.join(',')}}</span>
                  <span class="right-div" v-else>{{'--'}}</span>
                </div>
              </div>
              <div class="div-content">
                <div class="content-left specmeter left-flex">Order Time:</div>
                <div class="content-right">
                  <span class="right-div">{{created_time}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-orange d-flex align-items-center">
            <label class="form-check-label font-weight-bold fs-16">
              <input
                type="checkbox"
                v-model="selectallProducts"
                :checked="selectallProducts"
                @change="selectProducts($event)"
                class="form-check-input"
              />
              <span class="checkbox-icon"></span>
              Select All
            </label>
            <h6 class="content-center font-weight-bold select-title" style="bottom:0px !important;">SELECT PRODUCTS TO MOVE</h6>
          </div>
          <div class>
            <table class="table move-items">
              <thead>
                <tr>
                  <th style="width:40%">Product</th>
                  <th style="width:10%" class="text-center">Qty</th>
                  <th style="width:20%" class="text-center">Discount</th>
                  <th style="width:30%" class="text-center">Move Qty</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(course,cindex) in orders.course_details">
                  <tr :key="cindex" style="height:40px !important;background-color:#fff!important;">
                    <td style="width:40%" colspan="4">
                      <label class="form-check-label font-weight-bold">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          @change="selectCourse($event, course.course)"
                          :checked="course.course_checked"
                          v-model="course.course_checked"
                          :value="course.course"
                        />
                        <span class="checkbox-icon fs-16"></span>
                        <span class="text-secondary">Course {{course.course}}</span>
                      </label>
                    </td>
                  </tr>
                  <template v-for="(item, item_index) in course.item_details">
                    <tr :key="item_index">
                      <td style="width:40%">
                        <label class="form-check-label font-weight-bold">
                          <input
                            @change="updateSelectAll($event, item._id,cindex)"
                            type="checkbox"
                            :checked="item.item_checked"
                            v-model="item.item_checked"
                            :value="item._id"
                            class="form-check-input"
                          />
                          <span class="checkbox-icon fs-16"></span>
                          <el-tooltip
                            v-if="item.product_type == 'Non-Veg'"
                            content="Non-Veg"
                            placement="top"
                          >
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="width: 11px;" />
                          </el-tooltip>
                          <el-tooltip
                            v-else-if="item.product_type == 'Veg'"
                            content="Veg"
                            placement="top"
                          >
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="width: 11px;" />
                          </el-tooltip>
                          <el-tooltip
                            v-else-if="item.product_type == 'Egg'"
                            content="Egg"
                            placement="top"
                          >
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="width: 11px;" />
                          </el-tooltip>
                          <el-tooltip
                            v-else-if="item.product_type == 'Pescatarian'"
                            content="Pescatarian"
                            placement="top"
                          >
                            <img style="width:11px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                          </el-tooltip>
                          <span
                            class="text-secondary"
                            style="padding-left: 7px;"
                          >{{item.product_name}}</span>
                        </label>
                      </td>
                      <td style="width:10%" class="text-center font-weight-bold">{{item.quantity}}</td>
                      <td
                        style="width:20%"
                        class="text-center font-weight-bold"
                      >{{item.discount.amount}}</td>
                      <td style="width:30%" class="text-center">
                        <div
                          class="form-check form-check-inline moz-button"
                          v-if="item.item_checked"
                        >
                          <span v-if="item.qty <= 1">
                            <img
                              src="https://cdn.digicollect.com/cdn/pos/images-new-theme/disable-minus.svg"
                              style="width: 25px; height: 25px !important;"
                            />
                          </span>
                          <span v-else-if="item.quantity > 1" @click="decreaseQty(item._id,cindex)">
                            <img
                              src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qty-minus.svg"
                              style="width: 25px; height: 25px !important;"
                            />
                          </span>
                          <label class="form-check-label">
                            <input
                              id="m-qty"
                              v-validate="{min:1}"
                              :name="'move_qty'+item_index.toString()"
                              @input="moveQty(item,cindex)"
                              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                              type="number"
                              min="1"
                              v-model.number="item.qty"
                              max="1000"
                              class="move-qty"
                              aria-required="true"
                              aria-invalid="false"
                            />
                          </label>
                          <span v-if="item.qty == item.quantity" class="pl-3">
                            <img
                              src="https://cdn.digicollect.com/cdn/pos/images-new-theme/disable-plus.svg"
                              style="width: 25px; height: 25px !important;"
                            />
                          </span>
                          <span @click="increaseQty(item._id,cindex)" class="pl-3" v-else>
                            <img
                              src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-qty.svg"
                              style="width: 25px; height: 25px !important;"
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr v-if="item.addons.length > 0">
                      <td
                        colspan="6"
                        style="padding: 0px 12px !important;"
                      >
                        <div style="background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.6rem 1.2rem!important;">
                          <span class="text-green font-600">Add-On :</span>
                          <br />
                          <div
                            class="flex-adons pt-1"
                            v-for="(addon, addon_index) in item.addons"
                            :key="addon_index"
                          >
                            <div
                              class="showType"
                              :class="{'veg':addon.type_name == 'Veg','nonveg':addon.type_name == 'Non-Veg','egg':addon.type_name == 'Egg','pescatarian':addon.type_name == 'Pescatarian'}"
                            >
                              <el-tooltip :content="addon.type_name" placement="top">
                                <div></div>
                              </el-tooltip>
                            </div>
                            <span>
                              {{addon.name}}
                              <span class="text-green">X {{addon.quantity}}</span>
                              <span
                                class="text-green"
                                v-if="addon_index != item.addons.length - 1"
                              >,</span>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr> -->
                    <tr v-if="item.modifiers" :key="item_index+'item_index'">
                      <td
                        colspan="6"
                        style="padding: 0px 12px !important;"
                      >
                        <div style="background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.6rem 1.2rem!important;">
                          <span class="text-green font-600">Modifiers :</span>
                          <br />
                          <template v-if="item.modifiers.hasOwnProperty('for_secondary')">
                            <div
                                class="flex-adons pt-1"
                                v-for="(modifier, modifier_index) in item.modifiers.for_secondary"
                                :key="modifier_index"
                            >
                                <!-- <div
                                class="showType"
                                :class="{'veg':modifier.type_name == 'Veg','nonveg':modifier.type_name == 'Non-Veg','egg':modifier.type_name == 'Egg','pescatarian':modifier.type_name == 'Pescatarian'}"
                                >
                                <el-tooltip :content="modifier.type_name" placement="top">
                                    <div></div>
                                </el-tooltip>
                                </div> -->
                                <span>
                                {{modifier.m_item_name}}
                                <span class="text-green">X {{modifier.quantity}}</span>
                                <span
                                    class="text-green"
                                    v-if="modifier_index != item.modifiers.for_secondary.length - 1"
                                >,</span>
                                </span>
                            </div>
                          </template>
                          <template v-if="item.modifiers.hasOwnProperty('for_secondary')">
                            <div
                                class="flex-adons pt-1"
                                v-for="(modifier, modifier_index) in item.modifiers.for_primary"
                                :key="modifier_index"
                            >
                                <!-- <div
                                class="showType"
                                :class="{'veg':modifier.type_name == 'Veg','nonveg':modifier.type_name == 'Non-Veg','egg':modifier.type_name == 'Egg','pescatarian':modifier.type_name == 'Pescatarian'}"
                                >
                                <el-tooltip :content="modifier.type_name" placement="top">
                                    <div></div>
                                </el-tooltip>
                                </div> -->
                                <span class="fw-bold text-capitalize whiteSpace text-secondary">{{modifier.modifier_name}} :</span>
                                <span v-for="(modifier_item, index_mod_item) in modifier.modifier_items" :key="index_mod_item+'fcfoom'" class="pl-2">
                                    {{modifier_item.m_item_name}}
                                    <span class="text-green">X {{modifier_item.quantity}}</span>
                                    <span
                                        class="text-green"
                                        v-if="index_mod_item != modifier.modifier_items.length - 1"
                                    >,</span>
                                </span>
                            </div>
                          </template>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <tr
                  v-show="selected_product_ids.length>0"
                  class="subtotal"
                  v-for="(category, category_index) in categories"
                  :key="category_index"
                >
                  <td style="width:40%"></td>
                  <td style="width:10%" class="font-weight-bold"></td>
                  <td style="width:20%" class="font-weight-bold">
                    <span class="d-block c-name">{{category.name}}</span>
                  </td>
                  <td style="width:30%" class="font-weight-bold">{{category.amount}}</td>
                </tr>
                <tr v-show="selected_product_ids.length>0">
                  <td style="width:40%"></td>
                  <td style="width:10%" class="font-weight-bold"></td>
                  <td style="width:20%" class="font-weight-bold">Item Discount</td>
                  <td style="width:30%" class="font-weight-bold">{{item_discount}}</td>
                </tr>
                <tr v-show="selected_product_ids.length>0" class="bg-orange" style="height:29px;">
                  <td style="width:40%"></td>
                  <td style="width:10%"></td>
                  <td style="width:20%" class="font-weight-bold fs-16 text-black">Sub-Total</td>
                  <td style="width:30%" class="font-weight-bold fs-16 text-black">{{sub_total}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="v-modal-dialog-actions modal-bg" style="border-radius: 0px 0px 15px 15px;">
        <a class="btn btn-link btn-smm" @click="discardMoveItems()">DISCARD</a>
        <button
          class="btn btn-secondary btn-smm text-uppercase"
          v-if="selected_product_ids.length>0"
          @click="storeMoveItems()"
        >NEXT</button>
        <button class="btn btn-secondary btn-smm text-uppercase" v-else disabled>NEXT</button>
      </div>
    </div>
  </modal>
</template>
<script>
import Swal from 'sweetalert2'
import { SweetModal } from 'sweet-modal-vue'
import MoveItemsService from './mixins/move_items.js'
export default {
  props: ['modal_name', 'move_orders', 'created_time', 'dineIn'],
  mixins: [MoveItemsService],
  data() {
    return {
      selectallProducts: false,
      showMoveQty: [],
      showCourseQty: [],
      tempcourse: [],
      disableProgress: true,
      orders: this.move_orders,
      categories: [],
      item_discount: '',
      sub_total: '',
      moveItems: {
        order_id: '',
        items: []
      },
      moveItemsid: [],
      temp_length: '',
      checkcourse: [],
      selected_product_ids: [],
      selected_course_numbers: []
    }
  },
  methods: {
    discardMoveItems() {
      Swal.fire({
        title: 'Alert',
        text: 'Are you sure want to discard',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'DISCARD'
      })
        .then(result => {
          if (result.value) {
            try {
              this.$emit('hideMoveItems')
              this.showMoveQty = []
              this.showCourseQty = []
            } catch (error) {}
          }
        })
        .catch(error => {})
    },
    storeMoveItems() {
      this.$validator.validate().then(result => {
        if (result == true) {
          const result = []
          this.orders.course_details.forEach((course, cindex) => {
            const map = new Map()
            for (const item of this.orders.course_details[cindex]
              .item_details) {
              if (!map.has(item._id)) {
                map.set(item._id, true) // set any value to Map
                result.push({
                  _id: item._id,
                  quantity: item.qty
                })
              }
            }
          })
          this.moveItems = {
            order_id: this.orders._id,
            items: result
          }
          console.log(this.moveItems)
          this.$emit('hideMoveItems')
          this.$router.push({
            name: 'TableSelection',
            params: {
              disableProgress: this.disableProgress,
              selectedProducts: this.moveItems,
              dineIn: this.dineIn
            }
          })
        } else {
          this.$toasted.global.error('Move Quantity must be greater than 0')
        }
      })
    },
    async increaseQty(itemid, cindex) {
      this.orders.course_details[cindex].item_details.forEach((item, index) => {
        if (item._id == itemid) {
          item.qty++
        }
      })
      const result = []
      this.orders.course_details.forEach((course, cindex) => {
        for (const item of this.orders.course_details[cindex].item_details) {
          const map = new Map()
          for (const item of this.orders.course_details[cindex]
            .item_details) {
            if (!map.has(item._id)) {
              map.set(item._id, true) // set any value to Map
              result.push({
                _id: item._id,
                quantity: item.qty
              })
            }
          }
        }
      })
      this.moveItems = {
        order_id: this.orders._id,
        items: result
      }
      let response = await this.orderMoveItemsSummary(this.moveItems)
      this.categories = response.response.categories
      this.item_discount = response.response.item_discount
      this.sub_total = response.response.sub_total
    },
    async decreaseQty(itemid, cindex) {
      this.orders.course_details[cindex].item_details.forEach((item, index) => {
        if (item._id == itemid) {
          item.qty--
        }
      })
      const result = []
      this.orders.course_details.forEach((course, cindex) => {
        const map = new Map()
        for (const item of this.orders.course_details[cindex]
          .item_details) {
          if (!map.has(item._id)) {
            map.set(item._id, true) // set any value to Map
            result.push({
              _id: item._id,
              quantity: item.qty
            })
          }
        }
      })
      this.moveItems = {
        order_id: this.orders._id,
        items: result
      }
      let response = await this.orderMoveItemsSummary(this.moveItems)
      this.categories = response.response.categories
      this.item_discount = response.response.item_discount
      this.sub_total = response.response.sub_total
    },
    async moveQty(item, cindex) {
      this.orders.course_details[cindex].item_details.forEach((item, index) => {
        if (item._id == item._id) {
          if (item.qty > item.quantity) {
            this.orders.course_details[cindex].item_details[index].qty = 1
            this.$toasted.global.error(
              'Move Quantity cannot be greater than quantity of an Item'
            )
          } else if (item.qty == '') {
            this.orders.course_details[cindex].item_details[index].qty = 0
          }
        }
      })
      const result = []
      this.orders.course_details.forEach((course, cindex) => {
        const map = new Map()
        for (const item of this.orders.course_details[cindex]
          .item_details) {
          if (!map.has(item._id)) {
            map.set(item._id, true) // set any value to Map
            result.push({
              _id: item._id,
              quantity: item.qty
            })
          }
        }
      })
      this.moveItems = {
        order_id: this.orders._id,
        items: result
      }
      let response = await this.orderMoveItemsSummary(this.moveItems)
      this.categories = response.response.categories
      this.item_discount = response.response.item_discount
      this.sub_total = response.response.sub_total
    },
    async selectProducts(event) {
      if (event.target.checked) {
        this.orders.course_details.forEach((course, cindex) => {
          course.course_checked = true
          this.selected_course_numbers.push(this.orders.course_details[cindex].course);
          this.selected_course_numbers = [...new Set(this.selected_course_numbers)];
          this.orders.course_details[cindex].item_details.forEach(
            (item, item_index) => {
              this.orders.course_details[cindex].selected_product_ids.push(item._id)
              this.orders.course_details[cindex].selected_product_ids = [...new Set(this.orders.course_details[cindex].selected_product_ids)]
              this.selected_product_ids.push(item._id)
              this.selected_product_ids = [...new Set(this.selected_product_ids)]
              item.item_checked = true
              this.orders.course_details[cindex].item_details[
                item_index
              ].qty = this.orders.course_details[cindex].item_details[
                item_index
              ].quantity
            }
          )
        });
      } else {
        this.orders.course_details.forEach((course, cindex) => {
          this.selected_course_numbers = []
          this.selected_product_ids = []
          course.course_checked = false
          this.orders.course_details[cindex].item_details.forEach(
            (item, item_index) => {
              this.orders.course_details[cindex].selected_product_ids = []
              item.item_checked = false
              this.orders.course_details[cindex].item_details[
                item_index
              ].qty = 0
            }
          )
        })
      }
      this.$forceUpdate();
      const result = []
      this.orders.course_details.forEach((course, cindex) => {
        const map = new Map()
        for (const item of this.orders.course_details[cindex]
          .item_details) {
          if (!map.has(item._id)) {
            map.set(item._id, true) // set any value to Map
            result.push({
              _id: item._id,
              quantity: item.qty
            })
          }
        }
      })
      this.moveItems = {
        order_id: this.orders._id,
        items: result
      }
      console.log(this.moveItems)
      try {
        let response = await this.orderMoveItemsSummary(this.moveItems)
        this.categories = response.response.categories
        this.item_discount = response.response.item_discount
        this.sub_total = response.response.sub_total
      } catch (error) {
        this.$log.debug(error)
      }
    },
    checkcourses() {
      return
    },
    async updateSelectAll(event, itemid, cindex) {
      if(event.target.checked) {
          this.orders.course_details[cindex].selected_product_ids.push(itemid);
          this.orders.course_details[cindex].selected_product_ids = [...new Set(this.orders.course_details[cindex].selected_product_ids)]
          this.selected_product_ids.push(itemid)
          this.selected_product_ids = [...new Set(this.selected_product_ids)]
          if(this.orders.course_details[cindex].selected_product_ids.length == this.orders.course_details[cindex].item_details.length) {
            this.orders.course_details[cindex].course_checked = true
          } else {
            this.orders.course_details[cindex].course_checked = false
          }
          this.orders.course_details[cindex].item_details.forEach(
          (product, index) => {
            if (product._id == itemid) {
              this.orders.course_details[cindex].item_details[
                index
              ].qty = this.orders.course_details[cindex].item_details[
                index
              ].quantity
            }
          })
      } else {
        let item_index = this.orders.course_details[cindex].selected_product_ids.findIndex(pr_id => pr_id == itemid);
        if(item_index >= 0) {
          this.orders.course_details[cindex].selected_product_ids.splice(item_index, 1)
        }
        let selected_item_index = this.selected_product_ids.findIndex(pr_id => pr_id == itemid);
        if(selected_item_index >= 0) {
          this.selected_product_ids.splice(item_index, 1)
        }
        this.orders.course_details[cindex].course_checked = false
        this.orders.course_details[cindex].item_details.forEach(
          (product, index) => {
            if (product._id == itemid) {
              this.orders.course_details[cindex].item_details[
                index
              ].qty = 0
            }
          })
      }
      this.orders.course_details.forEach((course, cindex)=>{
        if(course.course_checked) {
          this.selected_course_numbers.push(this.orders.course_details[cindex].course);
          this.selected_course_numbers = [...new Set(this.selected_course_numbers)];
        } else {
          let course_index = this.selected_course_numbers.findIndex(course => course == this.orders.course_details[cindex].course);
          if(course_index >=0 ) {
            this.selected_course_numbers.splice(course_index, 1);
          }
        }
      })
      if(this.selected_course_numbers.length == this.orders.course_details.length) {
        this.selectallProducts = true
      } else {
        this.selectallProducts = false
      }
      this.$forceUpdate();
      const result = []
      this.orders.course_details.forEach((course, cindex) => {
        const map = new Map()
        for (const item of this.orders.course_details[cindex]
          .item_details) {
          if (!map.has(item._id)) {
            map.set(item._id, true) // set any value to Map
            result.push({
              _id: item._id,
              quantity: item.qty
            })
          }
        }
      })
      this.moveItems = {
        order_id: this.orders._id,
        items: result
      }
      console.log(this.moveItems)
      try {
        let response = await this.orderMoveItemsSummary(this.moveItems)
        this.categories = response.response.categories
        this.item_discount = response.response.item_discount
        this.sub_total = response.response.sub_total
      } catch (error) {}
    },
    async selectCourse(event, courseno) {
      console.log(event.target.checked)
      if(event.target.checked) {
        this.orders.course_details.forEach((course, cindex) => {
          if (course.course == courseno) {
            this.orders.course_details[cindex].item_details.forEach(
              (item, index) => {
                item.item_checked = true
                this.orders.course_details[cindex].selected_product_ids.push(item._id);
                this.orders.course_details[cindex].selected_product_ids = [...new Set(this.orders.course_details[cindex].selected_product_ids)]
                this.selected_product_ids.push(item._id)
                this.selected_product_ids = [...new Set(this.selected_product_ids)]
                this.orders.course_details[cindex].item_details[index].qty = 0
              }
            )
          }
        })
      } else {
        this.orders.course_details.forEach((course, cindex) => {
          if (course.course == courseno) {
            this.orders.course_details[cindex].item_details.forEach(
              (item, index) => {
                this.orders.course_details[cindex].selected_product_ids = []
                let item_index = this.selected_product_ids.findIndex(pr_id => pr_id == item._id);
                if(item_index >= 0) {
                  this.selected_product_ids.splice(item_index, 1)
                }
                item.item_checked = false
                this.orders.course_details[cindex].item_details[
                  index
                ].qty = this.orders.course_details[cindex].item_details[
                  index
                ].quantity
              }
            )
          }
        })
      }
      this.orders.course_details.forEach((course, cindex)=>{
        if(course.course_checked) {
          this.selected_course_numbers.push(this.orders.course_details[cindex].course);
          this.selected_course_numbers = [...new Set(this.selected_course_numbers)];
        } else {
          let course_index = this.selected_course_numbers.findIndex(course => course == this.orders.course_details[cindex].course);
          if(course_index >=0 ) {
            this.selected_course_numbers.splice(course_index, 1);
          }
        }
      })
      if(this.selected_course_numbers.length == this.orders.course_details.length) {
        this.selectallProducts = true
      } else {
        this.selectallProducts = false
      }
      this.$forceUpdate();
      const result = []
      this.orders.course_details.forEach((course, cindex) => {
        const map = new Map()
        for (const item of this.orders.course_details[cindex]
          .item_details) {
          if (!map.has(item._id)) {
            map.set(item._id, true) // set any value to Map
            result.push({
              _id: item._id,
              quantity: item.qty
            })
          }
        }
      })
      this.moveItems = {
        order_id: this.orders._id,
        items: result
      }
      console.log(this.moveItems)
      try {
        let response = await this.orderMoveItemsSummary(this.moveItems)
        this.categories = response.response.categories
        this.item_discount = response.response.item_discount
        this.sub_total = response.response.sub_total
        if (this.orders.course_details.length == this.showCourseQty.length) {
          this.selectallProducts = true
        } else {
          this.selectallProducts = false
        }
      } catch (error) {}
    }
  },
  components: {
    SweetModal
  },
  mounted() {
    for (var courseindex in this.orders.course_details) {
      this.orders.course_details[courseindex].item_details.forEach(product => {
        this.moveItems.items.push({ _id: product._id, quantity: 0 })
      })
    }
    this.temp_length = this.moveItems.items.length
    this.orders.course_details.forEach((course, cindex) => {
      const result = []
      const map = new Map()
      course['course_checked'] = false
      course['selected_product_ids'] = []
      for (const item of this.orders.course_details[cindex].item_details) {
        if (!map.has(item._id)) {
          map.set(item.id, true) // set any value to Map
          result.push({
            _id: item._id,
            product_name: item.product_name,
            // addons: item.addons,
            modifiers: item.modifiers,
            course: item.course,
            discount: item.discount,
            price: item.price,
            product_id: item.product_id,
            product_price: item.product_price,
            product_price_unformatted: item.product_price_unformatted,
            product_type: item.product_type,
            quantity: item.quantity,
            total: item.total,
            qty: 0,
            item_checked: false
          })
        }
      }
      this.orders.course_details[cindex].item_details = result
    })
    this.moveItems.order_id = this.orders._id
  },
  beforeMount() {
    this.orders = JSON.parse(JSON.stringify(this.orders))
  },
  beforeDestroy() {
    this.$validator.pause()
  }
}
</script>
<style scoped>
.w-150 {
  width: 150px;
}
.move-customer {
  box-shadow: 0 4px 8px 0 rgba(162, 141, 141, 0.16);
  background-image: linear-gradient(95deg, #005dae 20%, #004fe3 100%);
  border-radius: 12px;
}
.whiteSpace {
  white-space: nowrap;
}
.content-left.specmeter {
  color: white !important;
  flex: 0 0 130px !important;
}
.left-flex.content-left.specmeter {
  color: white !important;
  flex: 0 0 90px !important;
}
.content-right {
  color: white !important;
}
.content-right .right-div {
  color: white !important;
}
.div-content {
  flex: 0 0 293px;
}
.bg-orange {
  background-color: #f5a623;
}
.content-center {
  margin: 0 auto;
}
.form-check-label input[type='checkbox'] ~ .checkbox-icon,
.form-check-label input[type='checkbox'] ~ .custom-control-indicator,
.custom-checkbox input[type='checkbox'] ~ .checkbox-icon,
.custom-checkbox input[type='checkbox'] ~ .custom-control-indicator,
.custom-radio input[type='checkbox'] ~ .checkbox-icon,
.custom-radio input[type='checkbox'] ~ .custom-control-indicator {
  font-size: 20px !important;
  position: relative;
  top: 1px;
  width: 31px;
}
.select-title {
  position: relative;
  right: 40px;
}
.move-items.table thead th {
  color: #303031 !important;
  border-bottom-style: dashed !important;
  text-transform: capitalize;
}
/* .move-items.table tbody tr {
  border-bottom: solid 1px #a8adb4;
} */
.move-items.table tbody td {
  padding: 0.4rem 0.8rem !important;
}
.final-modal.v-modal-layout {
  overflow: hidden !important;
}
.move-items.table
  tbody
  td
  .form-check-label
  input[type='checkbox']
  ~ .checkbox-icon,
.move-items.table
  tbody
  td
  .form-check-label
  input[type='checkbox']
  ~ .custom-control-indicator,
.move-items.table
  tbody
  td
  .custom-checkbox
  input[type='checkbox']
  ~ .checkbox-icon,
.move-items.table
  tbody
  td
  .custom-checkbox
  input[type='checkbox']
  ~ .custom-control-indicator,
.move-items.table
  tbody
  td
  .custom-radio
  input[type='checkbox']
  ~ .checkbox-icon,
.move-items.table
  tbody
  td
  .custom-radio
  input[type='checkbox']
  ~ .custom-control-indicator {
  font-size: 20px !important;
  position: relative;
  top: 1px;
  width: 12px !important;
  left: -23px !important;
}
a.btn-link {
  border: solid 1px red !important;
  color: red !important;
}
.move-qty {
  background-color: #cddbeb;
  border: none;
  text-align: center;
  outline: 0;
}
.form-check-label,
.custom-checkbox,
.custom-radio {
  padding-left: 11px !important;
}
.move-items.table tbody td .form-check-label,
.move-items.table tbody td .custom-checkbox,
.move-items.table tbody td .custom-radio {
  padding-left: 18px !important;
}
.form-check-label input[type='checkbox']:checked ~ .checkbox-icon,
.form-check-label input[type='checkbox']:checked ~ .custom-control-indicator,
.custom-checkbox input[type='checkbox']:checked ~ .checkbox-icon,
.custom-checkbox input[type='checkbox']:checked ~ .custom-control-indicator,
.custom-radio input[type='checkbox']:checked ~ .checkbox-icon,
.custom-radio input[type='checkbox']:checked ~ .custom-control-indicator {
  color: #00448b !important;
}
.v-modal-layout {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 420px !important;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
@-moz-document url-prefix() {
  .moz-button {
    display: ruby;
  }
}
.c-name {
  width: 95px;
  word-break: break-all;
}
</style>